import '../styles/templates/home.scss'

import { Link } from 'gatsby'
import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

import { PAGE_NAME } from '../../config/cms'
import { getFilePath } from '../../src/utils/getFilePath'
import { Button } from '../components/button'
import { Carousel } from '../components/carousel'
import { ArrowIndicator } from '../components/icons/arrow-indicator'
import { Layout } from '../components/layout'
import { ProductTile } from '../components/product-tile'
import { SoftwareTile } from '../components/software-tile'
import { Screen } from '../config/screens'
import { usePageData } from '../hooks/cms/use-page'
import { useProducts } from '../hooks/cms/use-products'
import { useSoftware } from '../hooks/cms/use-software'
import { useHrefMaker } from '../hooks/use-href-maker'
import { useScreen } from '../hooks/use-screen'
import { PageComponentFactory } from '../utils/pages'

const TEMPLATE_NAME = PAGE_NAME.Home

const HomePage = PageComponentFactory(() => {
  const { landingSection, productsSection, softwareSection, caseStudySection } =
    usePageData(TEMPLATE_NAME)
  const products = useProducts()
  const software = useSoftware()
  const makeHref = useHrefMaker()
  const isLarge = useScreen(Screen.Large)

  return (
    <Layout page={TEMPLATE_NAME} pathname="/">
      <section className="home-landing-section">
        <div className="container">
          <div className="home-landing-section__content">
            <svg
              className="home-landing-section__desktop-figure"
              viewBox="0 0 1161 375"
              preserveAspectRatio="none"
            >
              <path
                d="M1158 374.5V3H3V203.5L168.092 371.5H924"
                vectorEffect="non-scaling-stroke"
              />
            </svg>

            <svg
              className="home-landing-section__mobile-figure"
              viewBox="0 0 317 235"
              preserveAspectRatio="none"
            >
              <path d="M315 234V2H2V153.708L79.606 232.591H205.002" />
            </svg>
            <h1>{landingSection.heading}</h1>
            <div>{landingSection.caption}</div>
            <ArrowIndicator />
          </div>
        </div>
      </section>

      <section className="home-case-study-section">
        <div
          className="container"
          style={{
            backgroundImage: `url(${getFilePath(caseStudySection.image)})`,
          }}
        >
          <h4>{caseStudySection.label}</h4>
          <h3>{caseStudySection.heading}</h3>
          <p>{caseStudySection.description}</p>
          <a href={makeHref('/case-studies/')}>
            <Button variant="quaternary" icon={<BsArrowRight />}>
              {caseStudySection.buttonLabel}
            </Button>
          </a>
        </div>
      </section>

      <section className="home-products-section">
        <div className="container">
          <Link to={makeHref('/products')}>{productsSection.label}</Link>
          <h3>{productsSection.heading}</h3>
          <p>{productsSection.description}</p>
          <div>
            <Carousel
              visibleSlides={isLarge ? 2 : 1}
              slides={(productsSection.featuredProducts ?? [])
                .filter(identifier => !!products.table.get(identifier))
                .map(identifier => (
                  <ProductTile
                    key={identifier}
                    product={products.table.get(identifier)!}
                    forSlider
                    tagCursorDisabled
                  />
                ))}
            />
          </div>
        </div>
      </section>

      <section className="home-software-section">
        <div className="container">
          <Link to={makeHref('/software')}>{softwareSection.label}</Link>
          <h3>{softwareSection.heading}</h3>
          <div>
            {software.list.map((soft, index) => (
              <div
                className={`home-software-section__tile ${
                  (index + 1) % 2 ? '' : 'even'
                }`}
                key={index}
              >
                <SoftwareTile
                  key={soft.identifier}
                  alt
                  software={soft}
                  tagsDisabled
                  verticalPaddingDisabled
                  horizontalPaddingDisabled
                  buttonVariant="secondary"
                  wrappingDisabled
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
})

export default HomePage
